import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Grid, Button, Typography } from '@material-ui/core';
import theme from '../theme';

const useStyles = makeStyles(() => ({
    donateWrapper: {
        textAlign: 'center',
        marginBottom: theme.spacing(10),
        '& p a' : {
            textDecoration: 'none',
            color: theme.palette.common.mint,
            fontWeight: '600'
        }
    }
}));

const Donate = ({ content }) => {
    
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    return (
        <Grid container xs={12} justify='center'>
            <Grid
                item
                container
                xs={12}
                md={8}
                justify='center'
                className={classes.donateWrapper}
            >
                <Typography variant='h3'>
                    {content.mainTitle}
                </Typography>
                <Typography variant='body2'>
                    <div
                        dangerouslySetInnerHTML={{ __html: content.mainContent }}
                    />
                </Typography>
                
                
                <a href='https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=donate%40childaidproject.org&source=url&currency_code=EUR&'>
                    <Button variant='outlined' color='secondary'>
                        {content.donateButton}
                    </Button>
                </a>
                

                <Typography variant='body2'>
                    <div
                        dangerouslySetInnerHTML={{ __html: content.subContent }}
                    />
                </Typography>
                <Grid item xs={12}>
                    <Typography variant='h3'>
                        {content.shopTitle}
                    </Typography>
                    <Typography variant='body2'>
                        <div
                            dangerouslySetInnerHTML={{ __html: content.shopContent }}
                        />
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withRouter(Donate);
