import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
    typography: {
        fontFamily: 'Ubuntu',
        fontSize: 16,
        h2: {
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: '2rem',
            letterSpacing: '2px',
            lineHeight: '2rem',
            marginBottom: '0',
            display: 'block',
            width: '100%'
        },
        h3: {
            textAlign: 'center',
            paddingTop: '16px',
            marginTop: '24px',
            marginBottom: '24px',
            letterSpacing: '3px',
            fontSize: '2rem',
            color: 'rgb(96, 96, 96)',
            fontWeight: 'bold'
        },
        body2: {
            fontSize: '1.15rem',
            letterSpacing: '2px',
            lineHeight: '1.3',
            color: 'rgb(96, 96, 96)',
        }
    },
    palette: {
        common: {
            clearWhite: '#fff',
            white: 'rgb(244, 237, 233)',
            black: 'rgb(34, 35, 34)',
            red: 'rgb(240, 99, 105)',
            orange: 'rgb(252, 160, 120)',
            violet: 'rgb(109, 107, 255)',
            gray: '#f7f3f4',
            beige: '#f3eee8',
            mint: '#5ca1a7',
            introGray: 'rgb(96, 96, 96)',
        },
        primary: {
            main: '#556cd6',
        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: '#A40',
        },
        background: {
            default: '#f3eee8',
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                html: {
                    overflowX: 'hidden',
                    maxWidth: '100%'
                },
                body: {
                    overflowX: 'clip',
                    maxWidth: '100%'
                }
            }
        },
        MuiButton: {
            root: {
                textTransform: 'none'
            },
            outlined: {
                letterSpacing: '2px',
                borderWidth: '2px',
                fontWeight: '400',
                display: 'inline-block',
                fontSize: '1.2rem',
                marginBottom: '24px',
                marginTop: '24px',
                borderRadius: '12px',
                '&:hover': {
                    borderWidth: '2px'
                },
            },
            outlinedSecondary: {
                border: '2px solid #5ca1a7',
                color: '#5ca1a7',
                '&:hover': {
                    borderWidth: '2px'
                },
            }
        }
    }
});

export default theme;
