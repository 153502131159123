import React from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    footerWrapper: {
        background: theme.palette.common.mint,
        paddingTop: theme.spacing(6),
        color: theme.palette.common.clearWhite,
        '& a': {
            color: theme.palette.common.clearWhite,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline'
            }
        },
    },
    footerLogo: {
        textTransform: 'uppercase',
        fontWeight: '600',
        fontSize: '1.8rem',
        lineHeight: '1.8rem'
    }
}));

const Footer = ({content}) => {
    const history = useHistory();
    const classes = useStyles();

    return (
        <div className={classes.footerWrapper}>
            <Container maxWidth='lg'>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={3} className={classes.footerLogo}>
                        Child<br/>aid<br/>Project.
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <strong>{content.account}</strong>
                        <div>
                            <p>
                                Child Aid Project e.V.
                                <br />
                                IBAN DE74440100460895133464
                                <br />
                                BIC PBNKDEFF
                            </p>
                            <p><b>PayPal</b><br/>
                            donate@childaidproject.org</p>
                            
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <strong>Social Media</strong>
                        <p>
                            <a href='https://www.instagram.com/Child.aid.project/'>Instagram</a>
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <strong>Legal</strong>
                        <p>
                            <Link to={{pathname: '/imprint', search: history.location.search}}>
                                {content.imprint}
                            </Link>
                        </p>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default withRouter(Footer);