import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter, useHistory, Link } from 'react-router-dom';
import { Grid, Button, Typography } from '@material-ui/core';
import theme from '../theme';

const payPalURL =
    'https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=donate%40childaidproject.org&source=url&currency_code=EUR&';

const useStyles = makeStyles(() => ({
    projectWrapper: {
        textAlign: 'center',
        marginBottom: theme.spacing(10),
    },
    text: {
        '& a' : {
            textDecoration: 'none',
            color: theme.palette.common.mint,
            fontWeight: '600'
        }
    },
    imageWrapper: {
        position: 'relative',
        '&::after': {
            bottom: '10%',
            content: "''",
            display: 'block',
            background: theme.palette.common.gray,
            borderRadius: '50%',
            position: 'absolute',
            width: '60vw',
            height: '450px',
            zIndex: '-1',
            [theme.breakpoints.down('md')]: {
                height: '300px',
                width: '80vw',
            }
        }
    },
    mainImage: {
        maxWidth: '60%',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100%'
        },
    },
    donateIcon: {
        display: 'block',
        borderRadius: '20px',
        width: '150px',
        height: '150px',
        padding: '0',
        background: 'none',
        margin: `${theme.spacing(1)}px auto`,
    },
    donateAmount: {
        fontSize: '0.9rem',
        color: theme.palette.common.introGray,
        fontWeight: 'bold',
    },
    donateDescription: {
        fontSize: '0.8rem',
        color: theme.palette.common.introGray,
    },
    donationsWrapper: {
        display: 'flex',
        flexWrap: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        },
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        '& a': {
            textDecoration: 'none',
            marginBottom: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',
            padding: `0 ${theme.spacing(1)}px`,
            flex: '1 0',
            '& img': {
                transition: 'ease .3s all'
            },
            '&:hover img': {
                scale: '1.4'
            }
        },
    },
}));

const Project = ({ project }) => {

    const history = useHistory();
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    return (
        <Grid container xs={12} justify='center'>
            <Grid
                item
                container
                xs={12}
                md={8}
                justify='center'
                className={classes.projectWrapper}
            >
                <div className={classes.imageWrapper}>
                    <img className={classes.mainImage} src={project.image} alt='' />
                </div>
                <Typography variant='h3'>
                    {project.title} - {project.subtitle}
                </Typography>
                <p>
                    <div
                        dangerouslySetInnerHTML={{ __html: project.content }}
                    />
                </p>
                <Grid
                    item
                    container
                    justify='space-around'
                    className={classes.donationsWrapper}
                >
                    {project.donations &&
                        project.donations.map(( donate ) => (
                            <a
                                href={
                                    payPalURL +
                                    'item_name=' +
                                    donate.purpose +
                                    '&amount=' +
                                    donate.amount
                                }
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <span className={classes.donateIcon}><img src={donate.icon} alt='' /></span>
                                <span className={classes.donateAmount}>
                                    { donate.amount } EURO
                                </span>
                            <p className={classes.donateDescription}>{donate.text}</p>   
                            </a>
                        ))}
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='body2'>
                        <div className={classes.text}
                            dangerouslySetInnerHTML={{ __html: project.more }}
                        />
                    </Typography>
                    <Link to={{pathname: '/donate', search: history.location.search}}>
                        <Button variant='outlined' color='secondary'>
                            {project.button}
                        </Button>
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withRouter(Project);
