import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { withRouter, Route, useHistory, Switch } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import theme from './theme.js';
import Content from './content.json';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import Home from './pages/home';
import Imprint from './pages/imprint';
import About from './pages/about';
import Donate from './pages/donate';
import Project from './pages/project';

const App = () => {
    const history = useHistory();
    let searchObj = { lang: 'de' };

    let langContent = () => Content[searchObj.lang];

    const searchObjParse = (search) => {
        let pairs = search.substring(1).split('&'),
            result = {};

        pairs.forEach((pair) => {
            pair = pair.split('=');
            result[pair[0]] = decodeURIComponent(pair[1] || '');
        });

        return JSON.parse(JSON.stringify(result));
    };

    const toggleLanguage = () => {
        history.push({
            search: `?lang=${searchObj.lang === 'de' ? 'en' : 'de'}`,
        });
        searchObj = searchObjParse(history.location.search);
    };

    if (!history.location.search.length) {
        console.log('NO SEARCH!');
        history.push({ search: '?lang=de' });
    } else {
        searchObj = searchObjParse(history.location.search);
    }

    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Header
                content={() => langContent()}
                lang={searchObj.lang}
                toggleLanguage={() => toggleLanguage()}
            />
            <Container maxWidth='lg'>
                <Switch>
                    <Route
                        exact
                        path='/'
                        render={(props) => (
                            <Home
                                {...props}
                                lang={searchObj.lang}
                                content={Content[searchObj.lang]}
                            />
                        )}
                    />
                    <Route
                        path='/imprint'
                        render={(props) => (
                            <Imprint
                            {...props}
                            lang={searchObj.lang}
                            />
                        )}
                        />
                    <Route
                        path='/about'
                        render={(props) => (
                            <About
                                {...props}
                                content={langContent().aboutPage}
                            />
                        )}
                    />
                    <Route
                        path='/donate'
                        render={(props) => (
                            <Donate
                                {...props}
                                content={langContent().donatePage}
                            />
                        )}
                    />
                    {langContent().projects.items.map((project) => (
                        <Route
                            key={project.id}
                            path={`/projects/${project.id}`}
                            render={(props) => (
                                <Project
                                    {...props}
                                    project={project}
                                />
                            )}
                        />
                    ))}
                </Switch>
            </Container>
            <Footer content={langContent().footer}/>
        </MuiThemeProvider>
    );
};

export default withRouter(App);
