import React from 'react';
import { withRouter } from 'react-router-dom';

import About from '../components/About';
import Projects from '../components/Projects';
import QuickFacts from '../components/QuickFacts';
import Contact from '../components/Contact';

const Home = (props) => {

    return (
        <>
            <About content={props.content.about} />
            <Projects content={props.content.projects} />
            <QuickFacts content={props.content.keyfacts} />
            <Contact content={props.content.contact}/>
        </>
    );
};

export default withRouter(Home);
