import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../theme';
import ImprintDE from '../content/imprint';

const useStyles = makeStyles(() => ({
    imprintWrapper: {
        paddingBottom: theme.spacing(10),
        '& a' : {
            textDecoration: 'none',
            color: theme.palette.common.mint,
            fontWeight: '600'
        }
    }
}));

const Imprint = ({lang}) => {

    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0)
    })

return (
    <div className={classes.imprintWrapper}>
            <ImprintDE lang={lang} />    
    </div>
);
};

export default Imprint;
