import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import theme from '../theme';

const useStyles = makeStyles(() => ({
    factsWrapper: {
        display: 'flex',
        textAlign: 'center',
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        paddingBottom: theme.spacing(4)
    },
    factsHeadline: {
        textAlign: 'center',
        paddingTop: '2rem',
        paddingBottom: '0',
        letterSpacing: '2px',
        fontSize: '1.2rem',
        color: theme.palette.common.introGray
    },
    fact: {
        textAlign: 'center',
    },
    violet: {
        color: theme.palette.common.violet
    },
    red: {
        color: theme.palette.common.red
    },
    orange: {
        color: theme.palette.common.orange
    },
    number: {
        fontSize: '7rem',
        letterSpacing: '10px',
        fontWeight: 'bold',
    },
    caption: {
        textTransform: 'uppercase',
        fontSize: '1rem',
        fontWeight: 'bold'
    },
}));

const QuickFacts = ({content}) => {
    const classes = useStyles();
    return (
        <Grid container item xs={12} justify='center' className={classes.factsWrapper}>

            <Grid item xs={12}>
                <h3 className={classes.factsHeadline}>{content.headline}</h3>
            </Grid>
            <Box display='flex' flexWrap='wrap' justifyContent='center' xs={12} md={6}>
            {content.facts &&
                content.facts.map((fact) => (
                    <Box mx={5} xs={12} style={{ color: fact.color }} className={classes.fact}>
                        <div className={classes.number}>{fact.count}</div>
                        <div className={classes.caption}>{fact.text}</div>
                    </Box>
                ))
            }   
            </Box>
            
        </Grid>
    );
};

export default QuickFacts;
