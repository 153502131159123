import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Container, Popover } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import theme from '../theme';

const useStyles = makeStyles(() => ({
    logo: {
        textTransform: 'uppercase',
        fontSize: '1.6rem',
        fontWeight: 'bold',
        width: '8rem',
        lineHeight: '1.8rem',
        letterSpacing: '2px',
        display: 'inline-block',
        color: 'inherit',
        textDecoration: 'none',
    },
    langToggleBtn: {
        textTransform: 'uppercase'
    },
    headerWrapper: {
        paddingTop: '1rem',
        marginBottom: theme.spacing(3)
    },
    donateBtn: {
        textTransform: 'uppercase',
        marginTop: "0"
    }
}));

const Header = ({ content, toggleLanguage, lang }) => {

    const history = useHistory();
    const classes = useStyles();
    const { header } = content();

    return (
        <Container maxWidth='lg'>
            <Grid container spacing={3} className={classes.headerWrapper}>
                <Grid item xs={4}>
                    <Link className={classes.logo} to='/'>
                        child aid project.
                    </Link>
                </Grid>

                <Grid item container justify='flex-end' xs={8}>
        
                    <Grid item>
                        <Button className={classes.langToggleBtn} onClick={toggleLanguage}>{lang === 'de' ? 'EN' : 'DE'}</Button>
                    </Grid>
                    
                    <Grid item>
                        <Link to={{pathname: '/donate', search: history.location.search}}>
                            <Button className={classes.donateBtn} variant='outlined' color='secondary'>{header.donate}</Button>
                        </Link>
                    </Grid>
                    
                </Grid>
            </Grid>
            <Popover>Popover Content</Popover>
        </Container>
    );
};

export default Header;
