import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography } from '@material-ui/core';
import { Link , useHistory} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    mission: {
        background: theme.palette.common.gray,
        textAlign: 'center',
        borderRadius: '30px',
        paddingBottom: theme.spacing(3),
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(6)
    },
    introText: {
        fontSize: '2.5rem',
        fontWeight: 'bold',
        lineHeight: '1.2',
        letterSpacing: '3px',
        marginBottom: theme.spacing(3),
        color: theme.palette.common.introGray,
        '& span': {
            color: theme.palette.common.mint
        }
    },
    aboutWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(4),
        }
    },
    mainImgWrapper: {
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(6),
        },
        '&::after': {
            bottom: '-5%',
            content: "''",
            display: 'block',
            background: theme.palette.common.gray,
            borderRadius: '50%',
            position: 'absolute',
            width: '120%',
            height: '450px',
            zIndex: '-1',
        }
    }
}));

const About = ({content}) => {
    const history = useHistory();
    const classes = useStyles();

    return (
        <>
            <Grid container alignItems='stretch'>
                <Grid item xs={12} md={6} className={classes.mainImgWrapper}>
                    <img src='/cap-start-illu.png' alt='Illustration about education' />
                </Grid>    
                <Grid item xs={12} md={6} className={classes.aboutWrapper}>
                    <div className={classes.introText} dangerouslySetInnerHTML={{ __html: content.aboutText }} />
                    <Link to={{pathname: '/donate', search: history.location.search}}>
                        <Button variant='outlined' color='secondary'>{content.helpBtn}</Button>
                    </Link>
                </Grid>
            </Grid>
            <Grid container justify='center' className={classes.mission}>
                <Grid item xs={11} md={10}>
                    <Typography variant="h3">{content.mission}</Typography>
                    <div>
                    <Typography variant="body-2">{content.missionText}</Typography>
                    </div> 
                    <Link to={{pathname: '/about', search: history.location.search}}>
                        <Button variant='outlined' color='secondary'>{content.missionBtn}</Button>
                    </Link>
                </Grid>
            </Grid>
        </>
    );
}

export default About;