import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    contact: {
        background: theme.palette.common.gray,
        textAlign: 'center',
        borderRadius: '30px 30px 0 0',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(6),
        position: 'relative'
    },
}));

const Contact = ({content}) => {
    const classes = useStyles();

    return(
        <Grid container justify='center' className={classes.contact}>
            <Grid item xs={12} md={8}>
                <Typography variant="h3">{content.headline}</Typography>
                <Typography variant="body2">{content.copy}</Typography>
                <a href='mailto:info@childaidproject.org'>
                    <Button variant='outlined' color='secondary'>{content.cta}</Button>
                </a>
            </Grid>
        </Grid>  )
}

export default Contact;