import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    projectTitle: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: '2rem',
        letterSpacing: '2px',
        lineHeight: '2rem',
        marginBottom: '0',
    },
    projectSubTitle: {
        textTransform: 'uppercase',
        fontWeight: 'normal',
        letterSpacing: '2px',
        fontSize: '1.5rem',
        lineHeight: '2rem',
    },
    projectContent: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    projectContentImage: {
        position: 'relative',
        '&::after': {
            bottom: '10%',
            content: "''",
            display: 'block',
            background: theme.palette.common.gray,
            borderRadius: '50%',
            position: 'absolute',
            width: '60vw',
            height: '450px',
            zIndex: '-1',
            [theme.breakpoints.down('md')]: {
                height: '300px',
                width: '80vw',
            }
        }
    },
    projectContainer: {
        display: 'flex',
        '&:nth-child(even)': {
            '& .left': {
                [theme.breakpoints.up('md')]: {
                    order: '2'
                },
                '&::after': {
                    right: '0',
                }
            },
            '& .right': {
                [theme.breakpoints.up('md')]: {
                    order: '1'
                },
                paddingRight: theme.spacing(4),
                textAlign: 'right'
            }
        }
    },

}));

const Projects = ({ content }) => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <>
            <Typography variant="h3">{content.headline}</Typography>
            <Grid container justify='center'>
                <Grid item container xs={12} spacing={4}>
                    {content.items &&
                        content.items.map((project) => (
                            <Grid item container xs={12} className={classes.projectContainer} key={project.id}>
                                <Grid className={[classes.projectContentImage, "left"]} item xs={12} md={7}>
                                    <img src={project.image} alt='' />
                                </Grid>
                                <Grid style={{ color: project.color }} className={[classes.projectContent, "right"]} item xs={12} md={5}>
                                    <p className={classes.projectTitle}>
                                        {project.title}
                                    </p>
                                    <span className={classes.projectSubTitle}>{project.subtitle}</span>
                                    <Link
                                        to={{
                                            pathname: `/projects/${project.id}`,
                                            search: history.location.search,
                                        }}
                                    >
                                        <Button variant='outlined' color='secondary'>
                                        {project.link}
                                        </Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        ))}
                </Grid>
            </Grid>
        </>
    );
};

export default Projects;
