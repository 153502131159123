import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { Grid, Button, Typography } from '@material-ui/core';
import theme from '../theme';

const useStyles = makeStyles(() => ({
    aboutWrapper: {
        textAlign: 'center',
        marginBottom: theme.spacing(10),
        '& p a' : {
            textDecoration: 'none',
            color: theme.palette.common.mint,
            fontWeight: '600'
        }
    },
    imageWrapper: {
        position: 'relative',
        '&::after': {
            bottom: '10%',
            content: "''",
            display: 'block',
            background: theme.palette.common.gray,
            borderRadius: '50%',
            position: 'absolute',
            width: '60vw',
            height: '450px',
            zIndex: '-1',
            [theme.breakpoints.down('md')]: {
                height: '300px',
                width: '80vw',
            }
        }
    },
    mainImage: {
        maxWidth: '60%',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100%'
        }
    }
}));

const About = ({ content }) => {
    
    const history = useHistory();
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    return (
        <Grid container xs={12} justify='center'>
            <Grid
                item
                container
                xs={12}
                md={8}
                justify='center'
                className={classes.aboutWrapper}
            >
                <div className={classes.imageWrapper}>
                <img className={classes.mainImage} src={content.image} alt='' />
                </div>
                <Typography variant='h3'>
                    {content.mainTitle}
                </Typography>
                <Typography variant='body2'>
                    <div
                        dangerouslySetInnerHTML={{ __html: content.mainContent }}
                    />
                </Typography>
                <Grid item xs={12}>
                    <Typography variant='h3'>
                        {content.transparancyTitle}
                    </Typography>
                    <Typography variant='body2'>
                        <div
                            dangerouslySetInnerHTML={{ __html: content.transparancyContent }}
                        />
                    </Typography>
                    <Link to={{pathname: '/donate', search: history.location.search}}>
                        <Button variant='outlined' color='secondary'>
                            {content.supportButton}
                        </Button>
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withRouter(About);
