import React from 'react'

const ImprintDe = ({lang}) => {

    console.log(lang)

    if(lang === 'de') {

    return (
        <div>
        <h1>IMPRESSUM</h1><div><br /></div>CHILD AID PROJECT E.V.<br />Osterstraße 46<br />20259 Hamburg<br />&nbsp;<br /><a href='mailto:info@childaidproject.org' >info@childaidproject.org</a><div><br /><div>
                    <div>VORSTANDSMITGLIEDER<br />
                    Maximilian Baier<br />Lina Tesch</div>
                    <div>Felix Ohlhauser</div>
                    <div><br />VEREINSREGISTERNUMMER<br />VR 24352</div>
                    <div>
                        <br />INHALTLICH VERANTWORTLICHE*R<br />Vorstandsmitglieder<br />(Kontaktdaten
                        wie oben)
                    </div>
                    <div><br /></div>
                    <div>ILLUSTRATIONEN<br />
                    Lisa Berns <a href="https://illusundgestalten.de">illusundgestalten.de</a>
                    </div>
                    <div><br /></div>
                    <div>WEBSITE BY<br />
                    Ivan Ushmorov <a href="https://ushmorov.com/">ushmorov.com</a><br />
                    </div>
                    <div><br /></div>
                    <h3>HAFTUNGSAUSSCHLUSS</h3>
                    <div>
                        1. INHALT DES ONLINEANGEBOTES<br />Child Aid Project e.V.
                        (nachfolgend als Betreiber der Website aufgeführt) übernimmt
                        keinerlei Gewähr für die Aktualität oder
                        Vollständigkeit&nbsp;der bereitgestellten Informationen.
                        Haftungsansprüche gegen den Betreiber, die sich auf Schäden
                        materieller oder ideeller Art beziehen, die durch&nbsp;die
                        Nutzung oder Nichtnutzung der dargebotenen Informationen bzw.
                        durch die Nutzung fehlerhafter und unvollständiger
                        Informationen&nbsp;verursacht wurden, sind grundsätzlich
                        ausgeschlossen, sofern seitens der Betreiber kein nachweislich
                        vorsätzliches oder grob fahrlässiges&nbsp;Verschulden vorliegt.
                        Alle Angebote sind freibleibend und unverbindlich. Der Betreiber
                        behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung
                        zeitweise oder endgültig&nbsp;einzustellen.<br /><br />
                    </div>
                    <div>
                        2. VERWEISE UND LINKS<br />Der Website-Betreiber erklärt hiermit
                        ausdrücklich, dass zum Zeitpunkt der Setzung von Verweisen auf
                        fremde Internetseiten ('Links') die entsprechenden verlinkten
                        Seiten frei von illegalen Inhalten waren. Auf die&nbsp;aktuelle
                        und zukünftige Gestaltung, die Inhalte oder die Urheberschaft
                        der verknüpften Seiten hat der Betreiber keinerlei Einfluss.
                        Deshalb distanziert sich der Betreiber hiermit ausdrücklich von
                        allen Inhalten aller&nbsp;verknüpften Seiten, die nach der
                        Linksetzung verändert wurden. Diese Feststellung gilt für alle
                        innerhalb des eigenen Internetangebotes gesetzten Links und
                        Verweise sowie für Fremdeinträge im Gästebuch. Für
                        illegale,&nbsp;fehlerhafte oder unvollständige Inhalte und
                        insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung
                        solcherart dargebotener Informationen entstehen, haftet allein
                        der Anbieter der Seite, auf welche verwiesen&nbsp;wurde, nicht
                        derjenige, der über Links auf die jeweilige Veröffentlichung
                        lediglich verweist.<br /><br />
                    </div>
                    <div>
                        3. URHEBER- UND KENNZEICHENRECHT<br />Der Betreiber ist
                        bestrebt, in allen Publikationen die Urheberrechte zu beachten,
                        von ihr selbst erstellte Grafiken, Tondokumente, Videosequenzen
                        und Texte zu nutzen oder auf lizenzfreie Dokumente
                        zurückzugreifen.&nbsp;Alle innerhalb des Internetangebotes
                        genannten und ggf. durch Dritte geschützten Marken- und
                        Warenzeichen unterliegen uneingeschränkt den Bestimmungen des
                        jeweils gültigen Kennzeichenrechts und den&nbsp;Besitzrechten
                        der jeweiligen eingetragenen Eigentümer. Allein aufgrund der
                        bloßen Nennung ist nicht der Schluss zu ziehen, dass
                        Markenzeichen nicht durch Rechte Dritter geschützt sind. Das
                        Copyright für&nbsp;veröffentlichte, von dem Betreiber selbst
                        erstellte Objekte bleibt allein bei den Autoren der Seiten. Eine
                        Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente,
                        Videosequenzen und Texte in anderen&nbsp;elektronischen oder
                        gedruckten Publikationen ist ohne ausdrückliche Zustimmung von
                        des Betreibers nicht gestattet.<br /><br />
                    </div>
                    <div>
                        4. DATENSCHUTZ<br />Unsere Datenschutzerklärung finden
                        Sie&nbsp;hier.&nbsp;Die Eingabe persönlicher oder geschäftlicher
                        Daten (E-Mail-Adressen, Namen, Anschriften) erfolgt auf
                        ausdrücklich freiwilliger Basis. Die Daten werden
                        nicht&nbsp;weitergegeben. Die Inanspruchnahme und Bezahlung
                        aller angebotenen Dienste ist – soweit technisch möglich und
                        zumutbar – auch ohne Angabe solcher Daten bzw. unter Angabe
                        anonymisierter Daten oder eines&nbsp;Pseudonyms gestattet.<br /><br />
                    </div>
                    <div>
                        5. SALVATORISCHE KLAUSEL<br />Sofern Teile oder einzelne
                        Formulierungen dieses Textes der geltenden Rechtslage nicht,
                        nicht mehr oder nicht vollständig entsprechen sollten, bleiben
                        die übrigen Teile des Dokumentes in ihrem Inhalt und
                        ihrer&nbsp;Gültigkeit davon unberührt. Alle Rechte vorbehalten.
                        Die Veröffentlichung des Inhalts dieses Web-Angebotes bedarf der
                        ausdrücklichen Genehmigung durch den Betreiber dieser Website.
                        Alle Bilder sind&nbsp;urheberrechtlich geschützt und dürfen nur
                        mit ausdrücklicher Genehmigung des Rechtinhabers verwendet
                        werden.
                    </div>
                    <div><br /></div>
                    <div><br /></div>
                    <h3>DATEN&shy;SCHUTZ</h3>
                    <div>
                        <br />Verantwortliche Stelle im Sinne der Datenschutzgesetze,
                        insbesondere der EU-Datenschutzgrundverordnung&nbsp;<wbr />(DSGVO),
                        ist der Vorstand von Child Aid Project e.V. –&nbsp;&nbsp;<a
                            href='mailto:info@childaidproject.org'>info@childaidproject.org</a
                        ><br /><br />
                    </div>
                    <div><br /></div>
                    <div>
                        BETROFFENENRECHTE<br />Unter den angegebenen Kontaktdaten kannst
                        du jederzeit folgende Rechte ausüben:<br />&nbsp;
                        <ul>
                        <li>
                            Auskunft über
                            deine bei uns gespeicherten Daten und deren Verarbeitung,
                        </li>
                        <li>
                            Berichtigung
                            unrichtiger personenbezogener Daten,
                        </li>
                        <li>
                            Löschung
                            deiner bei uns gespeicherten Daten,
                        </li>
                        <li>
                            Einschränkung
                            der Datenverarbeitung, sofern wir deine Daten aufgrund
                            gesetzlicher Pflichten noch nicht löschen dürfen,
                        </li>
                        <li>
                            Widerspruch
                            gegen die Verarbeitung deiner Daten bei uns und
                        </li>
                        <li>
                            Datenübertragbarkeit, sofern du in die Datenverarbeitung
                            eingewilligt hast oder einen Vertrag mit uns abgeschlossen
                            hast.
                        </li>
                        <li>
                            Sofern du uns
                            eine Einwilligung erteilt hast, kannst du diese jederzeit
                            mit Wirkung für die Zukunft widerrufen.
                        </li>
                        </ul>
                        &nbsp;Du kannst dich jederzeit mit einer Beschwerde an die
                        für dich zuständige Aufsichtsbehörde wenden. Deine zuständige
                        Aufsichtsbehörde richtet sich nach dem Bundesland deines
                        Wohnsitzes,&nbsp;deiner Arbeit oder der mutmaßlichen Verletzung.
                        Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen
                        Bereich) mit Anschrift findest du unter:&nbsp;<a
                            href='https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html'
                            
                            data-saferedirecturl='https://www.google.com/url?q=https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html&amp;source=gmail&amp;ust=1604949416232000&amp;usg=AFQjCNFxy0--t4pVe0QMKE8XWKuSyUKMfg'
                            >https://www.bfdi.bund.<wbr />de/DE/Infothek/Anschriften_<wbr />Links/anschriften_links-node.<wbr />html</a
                        >.<br /><br />
                    </div>
                    <div><br /></div>
                    <div>
                        LÖSCHUNG BZW. SPERRUNG DER DATEN<br />Wir halten uns an die
                        Grundsätze der Datenvermeidung und Datensparsamkeit. Wir
                        speichern deine personenbezogenen Daten daher nur so lange, wie
                        dies zur Erreichung der hier genannten&nbsp;Zwecke erforderlich
                        ist oder wie es die vom Gesetzgeber vorgesehenen vielfältigen
                        Speicherfristen vorsehen. Nach Fortfall des jeweiligen Zweckes
                        bzw. Ablauf dieser Fristen werden die&nbsp;entsprechenden Daten
                        routinemäßig und entsprechend den gesetzlichen Vorschriften
                        gesperrt oder gelöscht.<br /><br />
                    </div>
                    <div><br /></div>
                    <div>
                        ZWECKE DER DATENVERARBEITUNG DURCH DIE VERANTWORTLICHE STELLE
                        UND DRITTE<br />Wir verarbeiten deine personenbezogenen Daten
                        nur zu den in dieser Datenschutzerklärung genannten Zwecken.
                        Eine Übermittlung deiner persönlichen Daten an Dritte zu anderen
                        als den&nbsp;genannten Zwecken findet nicht statt. Wir geben
                        deine persönlichen Daten nur an Dritte weiter, wenn:
                        <ul>
                        <li>
                            Du deine ausdrückliche Einwilligung dazu erteilt hast,
                        </li>
                        <li>
                            die Verarbeitung zur Abwicklung eines Vertrags mit dir erforderlich ist,
                        </li>
                        <li>
                            die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung
                            erforderlich ist,
                        </li>
                        <li>
                            die Verarbeitung zur Wahrung berechtigter Interessen erforderlich ist und kein Grund zur Annahme besteht, dass du ein überwiegendes schutzwürdiges Interesse an der
                            Nichtweitergabe&nbsp;deiner Daten hast.
                        </li>
                        </ul>
                        <div><br /></div>
                        ERFASSUNG ALLGEMEINER INFORMATIONEN BEIM BESUCH UNSERER
                        WEBSITE<br />Wenn du auf unsere Website zugreifst, werden
                        automatisch mittels eines Cookies Informationen allgemeiner
                        Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten
                        etwa die Art des&nbsp;Webbrowsers, das verwendete
                        Betriebssystem, den Domainnamen Ihres Internet-Service-Providers
                        und ähnliches. Hierbei handelt es sich ausschließlich um
                        Informationen, welche keine&nbsp;Rückschlüsse auf deine Person
                        zulassen.<br />&nbsp;<br />&nbsp;<br />Diese Informationen sind
                        technisch notwendig, um von Ihnen angeforderte Inhalte von
                        Webseiten korrekt auszuliefern und fallen bei Nutzung des
                        Internets zwingend an. Sie werden&nbsp;insbesondere zu folgenden
                        Zwecken verarbeitet:<br />&nbsp;<br />
                        <ul>
                        <li>
                            Sicherstellung eines problemlosen Verbindungsaufbaus der Website,
                        </li>
                        <li>
                            Sicherstellung einer reibungslosen Nutzung unserer Website,
                        </li>
                        <li>
                            Auswertung der Systemsicherheit und -stabilität sowie
                        </li>
                        <li>
                            zu weiteren administrativen Zwecken.
                        </li>
                        </ul>
                        &nbsp;<br />Die Verarbeitung deiner personenbezogenen Daten
                        basiert auf unserem berechtigten Interesse aus den vorgenannten
                        Zwecken zur Datenerhebung. Wir verwenden deine Daten nicht,
                        um&nbsp;Rückschlüsse auf deine Person zu ziehen. Empfänger der
                        Daten sind nur die verantwortliche Stelle und ggf.
                        Auftragsverarbeiter.<br />&nbsp;<br />&nbsp;<br />Anonyme
                        Informationen dieser Art werden von uns ggfs. statistisch
                        ausgewertet, um unseren Internetauftritt und die
                        dahinterstehende Technik zu optimieren.<br /><br />
                    </div>
                    <div><br /></div>
                    <div>
                        REGISTRIERUNG AUF UNSERER WEBSITE<br />Bei der Registrierung für
                        die Nutzung unserer personalisierten Leistungen werden einige
                        personenbezogene Daten erhoben, wie Name, Anschrift, Kontakt-
                        und Kommunikationsdaten wie&nbsp;Telefonnummer und
                        E-Mail-Adresse. Bist du bei uns registriert, kannst du auf
                        Inhalte und Leistungen zugreifen, die wir nur registrierten
                        Nutzern anbieten. Angemeldete Nutzer haben zudem&nbsp;die
                        Möglichkeit, bei Bedarf die bei Registrierung angegebenen Daten
                        jederzeit zu ändern oder zu löschen. Selbstverständlich erteilen
                        wir dir darüber hinaus jederzeit Auskunft über die von&nbsp;uns
                        über dich gespeicherten personenbezogenen Daten. Gerne
                        berichtigen bzw. löschen wir diese auch auf deinen Wunsch,
                        soweit keine gesetzlichen Aufbewahrungspflichten
                        entgegenstehen.&nbsp;Zur Kontaktaufnahme in diesem Zusammenhang
                        nutze bitte die in dieser Datenschutzerklärung angegebenen
                        Kontaktdaten.<br /><br />
                    </div>
                    <div><br /></div>
                    <div>
                        SSL-VERSCHLÜSSELUNG<br />Um die Sicherheit Ihrer Daten bei der
                        Übertragung zu schützen, verwenden wir dem aktuellen Stand der
                        Technik entsprechende Verschlüsselungsverfahren (z.B. SSL) über
                        HTTPS.<br /><br />
                    </div>
                    <div><br /></div>
                    <div>
                        NEWSLETTER<br />Auf Grundlage deiner ausdrücklich erteilten
                        Einwilligung, übersenden wir dir regelmäßig unseren Newsletter
                        bzw. vergleichbare Informationen per E-Mail an deine angegebene
                        E-Mail-Adresse.<br />&nbsp;<br />&nbsp;<br />Unsere Newsletter
                        enthalten Informationen über unsere Vereinsarbeit, den Stand
                        unserer Projekte, unsere Aktionen, Events und Publikationen.
                        Insbesondere können Hinweise auf&nbsp;Blogbeiträge, Vorträge
                        oder Workshops, unsere Leistungen oder Onlineauftritte gehören.
                        Der Newsletter wird maximal einmal im Monat, zumindest aber
                        quartalsweise versandt.<br />Für den Empfang des Newsletters ist
                        die Angabe deiner E-Mail-Adresse ausreichend. Bei der Anmeldung
                        zum Bezug unseres Newsletters werden die von dir angegebenen
                        Daten&nbsp;ausschließlich für diesen Zweck verwendet. Abonnenten
                        können auch über Umstände per E-Mail informiert werden, die für
                        den Dienst oder die Registrierung relevant sind
                        (Beispielsweise&nbsp;Änderungen des Newsletterangebots oder
                        technische Gegebenheiten).<br />&nbsp;<br />&nbsp;<br />Für eine
                        wirksame Registrierung benötigen wir eine valide E-Mail-Adresse.
                        Um zu überprüfen, dass eine Anmeldung tatsächlich durch den
                        Inhaber einer E-Mail-Adresse erfolgt, setzen wir&nbsp;das
                        „Double-opt-in“-Verfahren ein. Hierzu protokollieren wir die
                        Bestellung des Newsletters, den Versand einer Bestätigungsmail
                        und den Eingang der hiermit angeforderten Antwort.
                        Weitere&nbsp;Daten werden nicht erhoben. Die Daten werden
                        ausschließlich für den Newsletterversand verwendet und nicht an
                        Dritte weitergegeben.<br />&nbsp;<br />&nbsp;<br />Die
                        Einwilligung zur Speicherung deiner persönlichen Daten und ihrer
                        Nutzung für den Newsletterversand kannst du jederzeit
                        widerrufen. Damit erlöscht gleichzeitig deine Einwilligungen
                        in&nbsp;dessen Versand via MailChimp und die statistischen
                        Analysen. Ein getrennter Widerruf des Versandes via MailChimp
                        oder der statistischen Auswertung ist leider nicht möglich.<br />&nbsp;<br />&nbsp;<br />In
                        jedem Newsletter findet sich dazu ein entsprechender Link.
                        Außerdem kannst du dich jederzeit auch direkt auf dieser
                        Webseite abmelden oder uns Ihren entsprechenden Wunsch über die
                        in&nbsp;dieser Datenschutzhinweise angegebene Kontaktmöglichkeit
                        mitteilen.<br /><br />
                    </div>
                    <div><br /></div>
                    <div>
                        EINSATZ DES VERSANDDIENSTLEISTERS “MAILCHIMP”<br />Der Versand
                        der Newsletter erfolgt mittels „MailChimp“, einer
                        Newsletterversandplattform des US-Anbieters Rocket Science
                        Group, LLC, 675 Ponce De Leon Ave NE #5000, Atlanta,
                        GA&nbsp;30308, USA.<br />&nbsp;<br />&nbsp;<br />Die
                        E-Mail-Adressen unserer Newsletterempfänger, als auch deren
                        weitere, im Rahmen dieser Hinweise beschriebenen Daten, werden
                        auf den Servern von MailChimp in den USA&nbsp;gespeichert.
                        MailChimp verwendet diese Informationen zum Versand und zur
                        Auswertung der Newsletter in unserem Auftrag. Des Weiteren kann
                        MailChimp nach eigenen Informationen&nbsp;diese Daten zur
                        Optimierung oder Verbesserung der eigenen Services nutzen, z.B.
                        zur technischen Optimierung des Versandes und der Darstellung
                        der Newsletter oder für wirtschaftliche&nbsp;Zwecke, um zu
                        bestimmen aus welchen Ländern die Empfänger kommen. MailChimp
                        nutzt die Daten unserer Newsletterempfänger jedoch nicht, um
                        diese selbst anzuschreiben oder an Dritte&nbsp;weiterzugeben.<br />&nbsp;<br />&nbsp;<br />Wir
                        vertrauen auf die Zuverlässigkeit und die IT- sowie
                        Datensicherheit von MailChimp. MailChimp ist unter dem
                        US-EU-Datenschutzabkommen „Privacy Shield“ zertifiziert und
                        verpflichtet&nbsp;sich damit die EU-Datenschutzvorgaben
                        einzuhalten. Des Weiteren haben wir mit MailChimp ein
                        „Data-Processing-Agreement“ abgeschlossen. Dabei handelt es sich
                        um einen Vertrag, in&nbsp;dem sich MailChimp dazu verpflichtet,
                        die Daten unserer Nutzer zu schützen, entsprechend dessen
                        Datenschutzbestimmungen in unserem Auftrag zu verarbeiten und
                        insbesondere nicht an&nbsp;Dritte weiter zu geben. Die
                        Datenschutzbestimmungen von MailChimp kannst du hier
                        einsehen:&nbsp;<a
                            href='http://www.mailchimp.com/legal/'
                            data-saferedirecturl='https://www.google.com/url?q=http://www.mailchimp.com/legal/&amp;source=gmail&amp;ust=1604949416232000&amp;usg=AFQjCNEaO0nNJbIq-_04ucV6jx8kw5ScFg'
                            >www.mailchimp.com/<wbr />legal/</a
                        >.<br /><br />
                    </div>
                    <div><br /></div>
                    <div>
                        STATISTISCHE ERHEBUNG UND ANALYSEN DES NEWSLETTERS<br />Die
                        Newsletter enthalten einen sog. „web-beacon“, d.h. eine
                        pixelgroße Datei, die beim Öffnen des Newsletters von dem Server
                        von MailChimp abgerufen wird. Im Rahmen dieses
                        Abrufs&nbsp;werden zunächst technische Informationen, wie
                        Informationen zum Browser und deinem System, als auch deine
                        IP-Adresse und Zeitpunkt des Abrufs erhoben. Diese Informationen
                        werden&nbsp;zur technischen Verbesserung der Services anhand der
                        technischen Daten oder der Zielgruppen und des Leseverhaltens
                        anhand derer Abruforte (die mit Hilfe der IP-Adresse bestimmbar
                        sind)&nbsp;oder der Zugriffszeiten genutzt.<br />&nbsp;<br />&nbsp;<br />Zu
                        den statistischen Erhebungen gehört ebenfalls die Feststellung,
                        ob die Newsletter geöffnet werden, wann sie geöffnet werden und
                        welche Links geklickt werden. Diese Informationen&nbsp;können
                        aus technischen Gründen zwar den einzelnen Newsletterempfängern
                        zugeordnet werden. Es ist jedoch weder unser Bestreben, noch das
                        von MailChimp, einzelne Nutzer zu&nbsp;beobachten. Die
                        Auswertungen dienen uns viel mehr dazu, die Lesegewohnheiten
                        unserer Nutzer zu erkennen und unsere Inhalte auf sie anzupassen
                        oder unterschiedliche Inhalte entsprechend&nbsp;den Interessen
                        unserer Nutzer zu versenden.<br /><br />
                    </div>
                    <div><br /></div>
                    <div>ONLINE AUFRUF UND DATENMANAGEMENT</div>
                    <div>
                        Es gibt Fälle, in denen wir die Newsletterempfänger auf die
                        Webseiten von MailChimp leiten. Z.B. enthalten unsere Newsletter
                        einen Link, mit dem die Newsletterempfänger die
                        Newsletter&nbsp;online abrufen können (z.B. bei
                        Darstellungsproblemen im E-Mailprogramm). Ferner können
                        Newsletterempfänger ihre Daten, wie z.B. die E-Mailadresse
                        nachträglich korrigieren. Ebenso ist&nbsp;die
                        Datenschutzerklärung von MailChimp nur auf deren Seite
                        abrufbar.<br />&nbsp;<br />&nbsp;<br />In diesem Zusammenhang
                        wiesen wir darauf hin, dass auf den Webseiten von MailChimp
                        Cookies eingesetzt und damit personenbezogene Daten durch
                        MailChimp, deren Partnern und&nbsp;eingesetzten Dienstleistern
                        (z.B. Google Analytics) verarbeitet werden. Auf diese
                        Datenerhebung haben wir keinen Einfluss.<br />&nbsp;<br />Weitere
                        Informationen können Sie der Datenschutzerklärung von MailChimp
                        entnehmen. Wir weisen dich zusätzlich auf die
                        Widerspruchsmöglichkeiten in die Datenerhebung
                        zu&nbsp;Werbezwecken auf den Webseiten&nbsp;<a
                            href='http://www.aboutads.info/choices'
                            
                            data-saferedirecturl='https://www.google.com/url?q=http://www.aboutads.info/choices&amp;source=gmail&amp;ust=1604949416232000&amp;usg=AFQjCNEvuJkR9WuJjHVVg3UlQEclozyieg'
                            >www.aboutads.info/<wbr />choices</a
                        >&nbsp;und&nbsp;<a
                            href='http://www.youronlinechoices.com'
                            data-saferedirecturl='https://www.google.com/url?q=http://www.youronlinechoices.com&amp;source=gmail&amp;ust=1604949416232000&amp;usg=AFQjCNFctijgxFt5h386huj0zsM4NxmG_g'
                            >www.<wbr />youronlinechoices.com</a
                        >&nbsp;(für den Europäischen Raum) hin.<br /><br />
                    </div>
                    <div><br /></div>
                    <div>
                        VERWENDUNG VON GOOGLE MAPS<br />Diese Webseite verwendet Google
                        Maps API, um geographische Informationen visuell darzustellen.
                        Bei der Nutzung von Google Maps werden von Google auch Daten
                        über die Nutzung der&nbsp;Kartenfunktionen durch Besucher
                        erhoben, verarbeitet und genutzt. Nähere Informationen über die
                        Datenverarbeitung durch Google kannst du den
                        Google-Datenschutzhinweisen entnehmen.&nbsp;Dort kannst du im
                        Datenschutzcenter auch deine persönlichen
                        Datenschutz-Einstellungen verändern.<br />Ausführliche
                        Anleitungen zur Verwaltung der eigenen Daten im Zusammenhang mit
                        Google-Produkten findest du&nbsp;hier.<br /><br />
                    </div>
                    <div><br /></div>
                    <div>
                        EINGEBETTETE YOUTUBE-VIDEOS<br />Auf einigen unserer Webseiten
                        betten wir Youtube-Videos ein. Betreiber der entsprechenden
                        Plugins ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA
                        94066, USA. Wenn du eine Seite&nbsp;mit dem YouTube-Plugin
                        besuchst, wird eine Verbindung zu Servern von Youtube
                        hergestellt. Dabei wird Youtube mitgeteilt, welche Seiten du
                        besuchst. Wenn du in deinem Youtube-Account&nbsp;eingeloggt
                        bist, kann Youtube dein Surfverhalten persönlich zuzuordnen.
                        Dies verhinderst du, indem du dich vorher aus deinem
                        Youtube-Account ausloggst.<br />Wird ein Youtube-Video
                        gestartet, setzt der Anbieter Cookies ein, die Hinweise über das
                        Nutzerverhalten sammeln.<br />&nbsp;<br />&nbsp;<br />Wer das
                        Speichern von Cookies für das Google-Ad-Programm deaktiviert
                        hat, wird auch beim Anschauen von Youtube-Videos mit keinen
                        solchen Cookies rechnen müssen. Youtube legt aber&nbsp;auch in
                        anderen Cookies nicht-personenbezogene Nutzungsinformationen ab.
                        Möchtest du dies verhindern, so musst du das Speichern von
                        Cookies im Browser blockieren.<br />&nbsp;<br />&nbsp;<br />Weitere
                        Informationen zum Datenschutz bei „Youtube“ findest du in der
                        Datenschutzerklärung des Anbieters unter:&nbsp;<a
                            href='http://www.google.de/intl/de/policies/privacy'
                            data-saferedirecturl='https://www.google.com/url?q=http://www.google.de/intl/de/policies/privacy&amp;source=gmail&amp;ust=1604949416232000&amp;usg=AFQjCNG-A8AcuqDG9t1KgXnoyJcjuzxiJA'
                            >www.google.de/intl/de/<wbr />policies/privacy</a
                        ><br /><br />
                    </div>
                    <div><br /></div>
                    <div>
                        EINSATZ VON INSTAGRAM EMBED<br />Innerhalb unseres
                        Onlineangebotes können Funktionen und Inhalte des Dienstes
                        Instagram, angeboten durch die Instagram Inc., 1601 Willow Road,
                        Menlo Park, CA, 94025, USA,&nbsp;eingebunden werden. Hierzu
                        können z.B. Inhalte wie Bilder, Videos oder Texte und
                        Schaltflächen gehören, mit denen Nutzer ihr Gefallen betreffend
                        die Inhalte kundtun, den Verfassern der&nbsp;Inhalte oder unsere
                        Beiträge abonnieren können. Sofern die Nutzer Mitglieder der
                        Plattform Instagram sind, kann Instagram den Aufruf der o.g.
                        Inhalte und Funktionen den dortigen Profilen&nbsp;der Nutzer
                        zuordnen. Die Datenschutzerklärung von Instagram findest du
                        hier:&nbsp;<a
                            href='http://instagram.com/about/legal/privacy/'
                            
                            data-saferedirecturl='https://www.google.com/url?q=http://instagram.com/about/legal/privacy/&amp;source=gmail&amp;ust=1604949416232000&amp;usg=AFQjCNG0Ey5hc7G54eshrRdJfs8JGABlvA'
                            >http://instagram.com/<wbr />about/legal/privacy/</a
                        >.<br />&nbsp;<br />&nbsp;<br />Auf unserer Website verwenden
                        wir das Plug-In „Instagram Feed“. Das Plug-In wird von der Smash
                        Balloon LLC in den USA (<a
                            href='https://smashballoon.com/'
                            
                            data-saferedirecturl='https://www.google.com/url?q=https://smashballoon.com/&amp;source=gmail&amp;ust=1604949416232000&amp;usg=AFQjCNFwx0PBPJkz6fLXuHQ69p63nyMDTQ'
                            >https://smashballoon.com/</a
                        >) entwickelt („Anbieter“) und wird&nbsp;als WordPress-Plug-In
                        oder als eigenständiges PHP-Plug-In angeboten. Das Plug-In
                        ermöglicht es uns den Newsfeed unserer Instagram-Fanpage direkt
                        auf unserer Website darzustellen.&nbsp;Hierzu ruft das Plug-In
                        die Informationen bei Instagram ab und bindet diese in unsere
                        Website ein. Nach derzeitigem Kenntnisstand werden hierbei keine
                        besonderen Cookies speziell von&nbsp;diesem Plug-In in deinem
                        Browser gesetzt, die personenbezogene Daten erheben, verarbeiten
                        oder nutzen. Dennoch können wir nicht gänzlich ausschließen,
                        dass unter Umständen&nbsp;personenbezogene Daten (z. B.
                        IP-Adressen, Browserdaten o.ä.) an den Anbieter des Plug-Ins
                        oder Instagram übermittelt werden. Wenn du einen Link in dem
                        Newsfeed anklickst, wirst du auf&nbsp;unseren Instagram-Kanal
                        weitergeleitetet. In diesem Fall speichert Instagram, von
                        welcher Website du unserem Instagram Kanal aufgerufen hast.
                        Näheres dazu, welche Daten von dir erhoben,&nbsp;verarbeitet
                        oder genutzt werden, wenn du die Seiten von Instagram aufrufst,
                        entnimmst du bitte der Datenschutzerklärung von Instagram.<br />&nbsp;<br />Der
                        komplette Funktionsumfang des Plug-Ins kann unter&nbsp;<a
                            href='https://de.wordpress.org/plugins/instagram-feed/'
                            
                            data-saferedirecturl='https://www.google.com/url?q=https://de.wordpress.org/plugins/instagram-feed/&amp;source=gmail&amp;ust=1604949416232000&amp;usg=AFQjCNFHCsAOJm3VmkItYqGeAlTvRc3Kbg'
                            >https://de.wordpress.<wbr />org/plugins/instagram-feed/</a
                        >&nbsp;<wbr />und&nbsp;<a
                            href='https://smashballoon.com/instagram-feed'
                            
                            data-saferedirecturl='https://www.google.com/url?q=https://smashballoon.com/instagram-feed&amp;source=gmail&amp;ust=1604949416232000&amp;usg=AFQjCNG2TmSd5CVI1a9cxprE9ugZWu5tsg'
                            >https://smashballoon.com/<wbr />instagram-feed</a
                        >&nbsp;eingesehen werden. Weitere&nbsp;Informationen zum
                        Datenschutz von Smash Balloon gibt es hier:&nbsp;<a
                            href='https://smashballoon.com/gdpr-and-our-plugins/'
                            
                            data-saferedirecturl='https://www.google.com/url?q=https://smashballoon.com/gdpr-and-our-plugins/&amp;source=gmail&amp;ust=1604949416232000&amp;usg=AFQjCNHDl3LANDfP28fWU4xEwvDXS7Gwng'
                            >https://smashballoon.<wbr />com/gdpr-and-our-plugins/</a
                        >&nbsp;Wenn du nicht wünschen, dass Instagram den Besuch unserer
                        Seiten deinem&nbsp;Instagram-Nutzerkonto zuordnen kann, logge
                        dich bitte aus deinem Instagram-Benutzerkonto aus.<br /><br />
                    </div>
                    <div><br /></div>
                    <div>
                        NUTZUNG DES KONTAKTFORMULARS<br />Wenn du uns per
                        Kontaktformular Anfragen zukommen lässt, werden deine Angaben
                        aus dem Anfrageformular inklusive der von dir dort angegebenen
                        Kontaktdaten zwecks Bearbeitung der&nbsp;Anfrage und für den
                        Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben
                        wir nicht ohne deine Einwilligung weiter.<br />&nbsp;<br />&nbsp;<br />Die
                        Verarbeitung der in das Kontaktformular eingegebenen Daten
                        erfolgt somit ausschließlich auf Grundlage deiner Einwilligung
                        (Art. 6 Abs. 1 lit. a DSGVO). Du kannst diese
                        Einwilligung&nbsp;jederzeit widerrufen. Dazu reicht eine
                        formlose Mitteilung per E‑Mail an uns. Die Rechtmäßigkeit der
                        bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom
                        Widerruf&nbsp;unberührt.<br />&nbsp;<br />&nbsp;<br />Eingetragene
                        Formulardaten werden dir per E-Mail geschickt, also auf deinem
                        E-Mail-Server gespeichert. Eine zusätzliche Speicherung in der
                        WordPress-Datenbank findet nicht statt.<br />SPENDENFORMULAR
                        FUNDRAISINGBOX<br />Diese Website nutzt FundraisingBox-<wbr />Spendenformulare
                        der Wikando GmbH, Schießgrabenstr. 32, 86150 Augsburg. Die
                        Wikando GmbH stellt für dieses Spendenformular die
                        technische&nbsp;Plattform für den Spendenvorgang zur Verfügung.
                        Die von dir bei der Spende eingegebenen Daten (z.B. Adresse,
                        Bankverbindung etc.) werden von der Wikando GmbH lediglich
                        zur&nbsp;Abwicklung der Spende auf Servern in Deutschland
                        gespeichert.<br />&nbsp;<br />&nbsp;<br />Wir haben mit der
                        Wikando GmbH einen Vertrag zur Auftragsdatenverarbeitung
                        abgeschlossen und setzen bei der Nutzung der Spendenformulare
                        der FundraisingBox die strengen Vorgaben&nbsp;der EU
                        Datenschutzgrundverordnung und der deutschen Datenschutzbehörden
                        vollständig um.<br />Die Übermittlung deiner Daten erfolgt auf
                        Grundlage von Art. 6 Abs. 1 lit. a DSGVO (Einwilligung) und Art.
                        6 Abs. 1 lit. b DSGVO (Verarbeitung zur Erfüllung eines
                        Vertrags).<br />&nbsp;<br />Soweit du die Einwilligung zur
                        Datenverarbeitung erteilt hast, kannst du deine Einwilligung
                        jederzeit widerrufen. Ein Widerruf wirkt sich auf die
                        Wirksamkeit von in der Vergangenheit&nbsp;liegenden
                        Datenverarbeitungsvorgängen nicht aus.
                    </div>
                    <div><br /></div>
                    <div><br />ÄNDERUNG UNSERER DATENSCHUTZBESTIMMUNGEN<br />Wir behalten
                        uns vor, diese Datenschutzerklärung anzupassen, damit sie stets
                        den aktuellen rechtlichen Anforderungen entspricht oder um
                        Änderungen unserer Leistungen in der&nbsp;Datenschutzerklärung
                        umzusetzen, z.B. bei der Einführung neuer Services. Für deinen
                        erneuten Besuch gilt dann die neue Datenschutzerklärung.<br />RECHTSGRUNDLAGEN
                        DER DATENSCHUTZVERORDNUNG<br />Entsprechend den Vorgaben der ab
                        dem 25. Mai 2018 geltenden Datenschutzgrundverordnung (DSGVO)
                        informieren wir dich, dass die Einwilligungen in den Versand der
                        E-Mailadressen auf&nbsp;Grundlage der Art.&nbsp;6&nbsp;Abs. 1
                        lit. a,&nbsp;7&nbsp;DSGVO sowie §&nbsp;7&nbsp;Abs. 2 Nr. 3, bzw.
                        Abs. 3 UWG erfolgt. Der Einsatz des Versanddienstleisters
                        MailChimp, Durchführung der statistischen Erhebungen&nbsp;und
                        Analysen sowie Protokollierung des Anmeldeverfahrens, erfolgen
                        auf Grundlage unserer berechtigten Interessen gem.
                        Art.&nbsp;6&nbsp;Abs. 1 lit. f DSGVO. Unser Interesse richtet
                        sich auf den&nbsp;Einsatz eines nutzerfreundlichen sowie
                        sicheren Newslettersystems, das sowohl unseren geschäftlichen
                        Interessen dient, als auch den Erwartungen der Nutzer
                        entspricht.<br />&nbsp;<br />&nbsp;<br />Wir weisen dich ferner
                        darauf hin, dass du der künftigen Verarbeitung deiner
                        personenbezogenen Daten entsprechend den gesetzlichen Vorgaben
                        gem. Art.&nbsp;21&nbsp;DSGVO jederzeit
                        widersprechen&nbsp;kannst. Der Widerspruch kann insbesondere
                        gegen die Verarbeitung für Zwecke der Direktwerbung erfolgen.<br /><br /></div><div><br /></div><div>FRAGEN<br />Wenn du Fragen zum Datenschutz hast, schreibe uns bitte eine E-Mail:&nbsp;<a href='mailto:info@childaidproject.org'>info@childaidproject.<wbr />org</a></div><div>&nbsp;<br />Die Datenschutzerklärung wurde mit dem Datenschutzerklärungs-<wbr />Generator der activeMind AG erstellt und auf die Bedürfnisse von Child Aid Project e.V. angepasst.</div></div></div>
                    </div>
                    );
                } else {
                    return (
                        <div>
                            <div>
    <h1>IMPRINT</h1>
    <div><br /></div>
    <span>CHILD AID PROJECT E.V.</span><br /><span>Osterstraße 46</span
    ><br /><span>20259 Hamburg</span><br /><span>&nbsp;</span><br /><a
        href="mailto:info@childaidproject.org"
        >info@childaidproject.org</a
    >
    <div><br /></div>
    <div>
        <div>
            <div>
                <span>BOARD MEMBERS</span><br /><span>Maximilian Baier</span
                ><br /><span>Lina Tesch</span>
            </div>
            <div>Felix Ohlhauser</div>
            <div>
                <span><br />REGISTER NUMBER</span><br /><span>VR 24352</span>
            </div>
            <div>
                <br />RESPONSIBLE FOR THE CONTENT<br />Board Members<br />(as
                above)
            </div>
            <div><br /></div>
            <div>ILLUSTRATIONS<br />
            Lisa Berns <a href="https://illusundgestalten.de">illusundgestalten.de</a>
            </div>
            <div><br /></div>
            <div>WEBSITE BY<br />
            Ivan Ushmorov <a href="https://ushmorov.com/">ushmorov.com</a><br /></div>
            <div><br /></div>

            <div><br /></div>
            <div>
                <div>
                    <span>DISCLAIMER</span>
                </div>
                <div>
                    <span><br /></span>
                </div>
                <div>
                    <span>1. CONTENT OF THE ONLINE OFFER</span>
                </div>
                <div>
                    <span
                        >Child Aid Project e.V. (listed below as the operator of
                        the website) assumes no liability for the topicality or
                        completeness of the information provided. Liability
                        claims against the operator relating to material or
                        non-material damage caused by the use or non-use of the
                        information provided or by the use of incorrect and
                        incomplete information are fundamentally excluded,
                        unless the operator can be shown to have acted willfully
                        or grossly negligent Is at fault. All offers are
                        non-binding. The operator expressly reserves the right
                        to change, add to, or delete parts of the pages or the
                        entire offer without prior notice, or to temporarily or
                        permanently discontinue publication.</span
                    >
                </div>
            </div>
            <div><br /></div>
            <div>
                <div>2. REFERENCES AND LINKS</div>
                <div>
                    The website operator hereby expressly declares that at the
                    time references were made to third-party websites ("links"),
                    the linked pages were free of illegal content. The operator
                    has no influence on the current and future design, content
                    or authorship of the linked pages. The operator therefore
                    hereby expressly distances himself from all content on all
                    linked pages that has been changed since the link was set.
                    This statement applies to all links and references set
                    within the company's own website as well as to external
                    entries in the guest book. For illegal, incorrect or
                    incomplete content and in particular for damage resulting
                    from the use or non-use of information presented in this
                    way, the provider of the page to which reference was made is
                    solely liable, not the person who merely refers to the
                    respective publication via links.
                </div>
                <br />
            </div>
            <div>
                <div>3. COPYRIGHT AND MARK RIGHTS</div>
                <div>
                    The operator endeavors to observe copyrights in all
                    publications, to use graphics, audio documents, video
                    sequences and texts it has created itself or to use
                    license-free documents. All brand names and trademarks
                    mentioned on the website and possibly protected by third
                    parties are subject without restriction to the provisions of
                    the applicable trademark law and the ownership rights of the
                    respective registered owner. The conclusion that trademarks
                    are not protected by the rights of third parties should not
                    be drawn simply because they are mentioned. The copyright
                    for published objects created by the operator himself
                    remains solely with the authors of the pages. Any
                    duplication or use of such graphics, audio files, video
                    sequences and texts in other electronic or printed
                    publications is not permitted without the express consent of
                    the operator.
                </div>
                <br />
            </div>
            <div>
                <div>4. PRIVACY POLICY</div>
                <div>
                    You can find our privacy policy here. The input of personal
                    or business data (email addresses, names, addresses) is done
                    on an expressly voluntary basis. The data will not be passed
                    on. The use and payment of all offered services is permitted
                    - if and so far technically possible and reasonable -
                    without entering such data or by entering anonymous data or
                    an alias.
                </div>
            </div>
            <div>
                <span><br /></span>
                <div><span>5. SEVERABILITY CLAUSE</span></div>
                <div>
                    <span
                        >If parts or individual formulations of this text do
                        not, no longer or not completely correspond to the
                        current legal situation, the remaining parts of the
                        document remain unaffected in their content and
                        validity. All rights reserved. The publication of the
                        content of this website requires the express approval of
                        the operator of this penis website. All images of dicks
                        are protected by copyright and may only be used with the
                        express permission of the copyright holder.</span
                    >
                </div>
            </div>
            <div><br /></div>
            <div><br /></div>
            <div><br /></div>
            <div><br /></div>
            <div>
                <div>
                    <span>DATA PROTECTION</span>
                </div>
                <div>
                    <span><br /></span>
                </div>
                <div>
                    <span
                        >Responsible body within the meaning of data protection
                        laws, in particular the EU General Data Protection
                        Regulation (GDPR), is the board of Child Aid Project
                        e.V. -
                        <a href="mailto:info@childaidproject.org"
                            >info@childaidproject.org</a
                        ></span
                    >
                </div>
            </div>
            <div><br /></div>
            <div>
                <div>AFFECTED RIGHTS</div>
                <div>
                    You can exercise the following rights at any time using the
                    contact details provided:
                </div>
                <div>&nbsp;</div>
                <ul>
                <li>Information about your data stored by us and their
                    processing,</li>
                <li>Correction of incorrect personal data,</li>
                <li>deletion of your data stored by us,</li>
                <li>
                    Restriction of data processing if we are not yet allowed
                    to delete your data due to legal obligations,
                </li>
                <li>Objection to the processing of your data by us and</li>
                <li>
                    Data portability, provided you have consented to data
                    processing or have concluded a contract with us.
                </li>
                <li>
                    If you have given us your consent, you can revoke it at
                    any time with effect for the future.
                </li>
                </ul>
            </div>
            <div>
                <div><br /></div>
                <span>&nbsp;</span>
                <span
                    ><br />You can contact the supervisory authority responsible
                    for you at any time with a complaint. Your competent
                    supervisory authority depends on the state of your place of
                    residence, your work or the alleged violation. You can find
                    a list of the supervisory authorities (for the non-public
                    area) with addresses at:
                    <a
                        href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                        data-saferedirecturl="https://www.google.com/url?q=https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html&amp;source=gmail&amp;ust=1605017252158000&amp;usg=AFQjCNG1UVIpcFv_9lsA80w9WNapkSYy6w"
                        >https://www.bfdi.bund.de/DE/<wbr />Infothek/Anschriften_Links/<wbr />anschriften_links-node.html</a
                    >.</span
                ><br /><br />
            </div>
            <div>
                <span><br /></span>
                <div><span>DELETION OR LOCKING THE DATA</span></div>
                <div>
                    <span
                        >We adhere to the principles of data avoidance and data
                        economy. We therefore only store your personal data for
                        as long as is necessary to achieve the purposes
                        mentioned here or as stipulated by the various storage
                        periods provided by law. After discontinuation of the
                        respective purpose or expiry of these deadlines, the
                        corresponding data will be routinely blocked or deleted
                        in accordance with the statutory provisions.</span
                    >
                </div>
                <br />
            </div>
            <div><br /></div>
            <div>
                <div>
                    <div>
                        PURPOSES OF DATA PROCESSING BY THE RESPONSIBLE BODY AND
                        THIRD PARTIES
                    </div>
                    <div>
                        We process your personal data only for the purposes
                        stated in this data protection declaration. A transfer
                        of your personal data to third parties for purposes
                        other than those mentioned does not take place. We will
                        only pass on your personal data to third parties if:
                    </div>
                    <ul>
                    <li>you have given your express consent to</li>
                    <li>the processing is necessary to process a contract with
                        you,
                    </li>
                    <li>the processing is necessary to fulfill a legal
                        obligation,
                    </li>
                    <li>processing is necessary to safeguard legitimate
                        interests and there is no reason to assume that you have
                        an overriding legitimate interest in not disclosing your
                        data.
                    </li>
                    </ul>
                </div>
                <div><br /></div>
                <div><br /></div>
                <div>
                    COLLECTION OF GENERAL INFORMATION WHEN VISITING OUR WEBSITE
                </div>
                <div>
                    When you access our website, information of a general nature
                    is automatically recorded using a cookie. This information
                    (server log files) includes, for example, the type of web
                    browser, the operating system used, the domain name of your
                    Internet service provider and the like. This is exclusively
                    information that does not allow any conclusions to be drawn
                    about your person.
                </div>
                <span>&nbsp;</span><br /><span>&nbsp;</span><br />
                <div>
                    <div>
                        This information is technically necessary in order to
                        correctly deliver the website content you have requested
                        and is mandatory when using the Internet. In particular,
                        they are processed for the following purposes:
                    </div>
                    <div>&nbsp;</div>
                    <ul>
                        <li>ensuring a problem-free connection to the website,</li>
                        <li>Ensuring the smooth use of our website,</li>
                        <li>Evaluation of system security and stability as well as</li>
                        <li>for further administrative purposes.</li>
                    </ul>
                </div>

                <span>&nbsp;<br /></span>
                <div>
                    <span
                        >The processing of your personal data is based on our
                        legitimate interest for the aforementioned data
                        collection purposes. We do not use your data to draw
                        conclusions about you personally. The recipient of the
                        data is only the responsible body and, if necessary, the
                        processor.</span
                    >
                </div>
                <div>
                    <span>&nbsp;</span>
                </div>
                <div>
                    <span>&nbsp;</span>
                </div>
                <div>
                    <span
                        >Anonymous information of this kind may be statistically
                        evaluated by us in order to optimize our website and the
                        technology behind it.</span
                    >
                </div>
                <div>
                    <span><br /></span>
                </div>
                <div>
                    <span><br /></span>
                </div>
                <div><span>REGISTRATION ON OUR WEBSITE</span></div>
                <div>
                    <span
                        >When registering for the use of our personalized
                        services, some personal data is collected, such as name,
                        address, contact and communication data such as
                        telephone number and e-mail address. If you are
                        registered with us, you can access content and services
                        that we only offer to registered users. Registered users
                        also have the option of changing or deleting the data
                        provided during registration at any time. Of course, we
                        will also provide you with information about the
                        personal data we have stored about you at any time. We
                        will be happy to correct or delete them at your request,
                        provided that there are no statutory retention
                        requirements. To contact us in this context, please use
                        the contact details given in this data protection
                        declaration.</span
                    >
                </div>
            </div>
            <div><br /></div>
            <div><br /></div>
            <div>
                <div>
                    <span>SSL ENCRYPTION</span>
                </div>
                <div>
                    <span>In order to protect the security of your data during
                        transmission, we use state-of-the-art encryption methods
                        (e.g. SSL) over HTTPS.</span>
                </div>
                <div>
                    <span><br /></span>
                </div>
                <div>
                    <span><br /></span>
                </div>
                <div>
                    <span>NEWSLETTER</span>
                </div>
                <div>
                    <span
                        >On the basis of your expressly given consent, we will
                        regularly send you our newsletter or comparable
                        information by email to the email address you
                        provided.</span
                    >
                </div>
                <div>
                    <span>&nbsp;</span>
                </div>
                <div>
                    <span>&nbsp;</span>
                </div>
                <div>
                    <span
                        >Our newsletters contain information about our
                        association work, the status of our projects, our
                        campaigns, events and publications. In particular,
                        references to blog posts, lectures or workshops, our
                        services or online appearances may be included. The
                        newsletter is sent out a maximum of once a month, but at
                        least every quarter.</span
                    >
                </div>
                <div>
                    <span
                        >To receive the newsletter, it is sufficient to provide
                        your e-mail address. When you register to receive our
                        newsletter, the data you provide will only be used for
                        this purpose. Subscribers can also be informed by email
                        about circumstances that are relevant for the service or
                        registration (e.g. changes to the newsletter offer or
                        technical circumstances).</span
                    >
                </div>
                <div>
                    <span>&nbsp;</span>
                </div>
                <div>
                    <span>&nbsp;</span>
                </div>
                <div>
                    <span
                        >We need a valid email address for effective
                        registration. In order to check that the registration is
                        actually made by the owner of an e-mail address, we use
                        the "double opt-in" procedure. For this purpose, we log
                        the order for the newsletter, the sending of a
                        confirmation email and the receipt of the response
                        requested. Further data is not collected. The data will
                        only be used to send the newsletter and will not be
                        passed on to third parties.</span
                    >
                </div>
                <div>
                    <span>&nbsp;</span>
                </div>
                <div>
                    <span>&nbsp;</span>
                </div>
                <div>
                    <span
                        >You can revoke your consent to the storage of your
                        personal data and its use for sending the newsletter at
                        any time. At the same time, your consent to its sending
                        via MailChimp and the statistical analyzes will expire.
                        A separate revocation of the dispatch via MailChimp or
                        the statistical evaluation is unfortunately not
                        possible.</span>
                </div>
                <div>
                    <span>&nbsp;</span>
                </div>
                <div>
                    <span>&nbsp;</span>
                </div>
                <div>
                    <span
                        >There is a corresponding link in every newsletter. You
                        can also unsubscribe directly on this website at any
                        time or inform us of your request using the contact
                        option given in this privacy policy.</span
                    >
                </div>
            </div>
            <div><br /></div>
            <div><br /></div>
            <div>
                <div>
                    <span
                        >USE OF THE SHIPPING SERVICE PROVIDER "MAILCHIMP"</span
                    >
                </div>
                <div>
                    <span
                        >The newsletter is sent via “MailChimp”, a newsletter
                        distribution platform from the US provider Rocket
                        Science Group, LLC, 675 Ponce De Leon Ave NE # 5000,
                        Atlanta, GA 30308, USA.</span
                    >
                </div>
                <div>
                    <span>&nbsp;</span>
                </div>
                <div>
                    <span>&nbsp;</span>
                </div>
                <div>
                    <span
                        >The e-mail addresses of our newsletter recipients, as
                        well as their other data described in these notes, are
                        stored on MailChimp's servers in the USA. MailChimp uses
                        this information to send and evaluate the newsletter on
                        our behalf. Furthermore, according to its own
                        information, MailChimp can use this data to optimize or
                        improve its own services, e.g. for the technical
                        optimization of the dispatch and the presentation of the
                        newsletter or for economic purposes in order to
                        determine from which countries the recipients come.
                        However, MailChimp does not use the data of our
                        newsletter recipients to write them down or to pass them
                        on to third parties.</span
                    >
                </div>
                <div>
                    <span>&nbsp;</span>
                </div>
                <div>
                    <span>&nbsp;</span>
                </div>
                <div>
                    <span
                        >We trust the reliability and IT and data security of
                        MailChimp. MailChimp is certified under the US-EU data
                        protection agreement "Privacy Shield" and is committed
                        to complying with EU data protection regulations. We
                        have also concluded a "Data Processing Agreement" with
                        MailChimp. This is a contract in which MailChimp
                        undertakes to protect the data of our users, to process
                        it on our behalf in accordance with its data protection
                        regulations and in particular not to pass it on to third
                        parties. You can view MailChimp's privacy policy here:
                        <a
                            href="http://www.mailchimp.com/legal/"
                            data-saferedirecturl="https://www.google.com/url?q=http://www.mailchimp.com/legal/&amp;source=gmail&amp;ust=1605017252159000&amp;usg=AFQjCNEdU-gqOh632zLis7BTlSWOxaHaoA"
                            >www.mailchimp.com/legal/</a
                        >.</span
                    >
                </div>
                <div>
                    <span><br /></span>
                </div>
                <div>
                    <span><br /></span>
                </div>
                <div>
                    <span
                        >STATISTICAL COLLECTION AND ANALYSIS OF THE
                        NEWSLETTER</span
                    >
                </div>
                <div>
                    <span
                        >The newsletters contain a so-called "web beacon", i.e.
                        a pixel-sized file that is retrieved from the MailChimp
                        server when the newsletter is opened. As part of this
                        retrieval, technical information, such as information
                        about the browser and your system, as well as your IP
                        address and the time of retrieval, are initially
                        collected. This information is used for the technical
                        improvement of the services based on the technical data
                        or the target groups and reading behavior based on their
                        retrieval locations (which can be determined using the
                        IP address) or the access times.</span
                    >
                </div>
                <div>
                    <span>&nbsp;</span>
                </div>
                <div>
                    <span>&nbsp;</span>
                </div>
                <div>
                    <span
                        >The statistical surveys also include determining
                        whether the newsletters are opened, when they are opened
                        and which links are clicked. For technical reasons, this
                        information can be assigned to the individual newsletter
                        recipients. However, it is neither our aim nor that of
                        MailChimp to observe individual users. The evaluations
                        serve us much more to recognize the reading habits of
                        our users and to adapt our content to them or to send
                        different content according to the interests of our
                        users.</span
                    >
                </div>
            </div>
            <div><br /></div>
            <div><br /></div>
            <div>
                <div><span>ONLINE CALL AND DATA MANAGEMENT</span></div>
                <div>
                    <span
                        >There are cases in which we direct the newsletter
                        recipients to the MailChimp websites. E.g. our
                        newsletters contain a link with which the newsletter
                        recipients can access the newsletter online (e.g. if
                        there are display problems in the e-mail program).
                        Furthermore, newsletter recipients can use their data,
                        such as correct the e-mail address later. MailChimp's
                        privacy policy is also only available on their
                        website.</span
                    >
                </div>
                <div>
                    <span>&nbsp;</span>
                </div>
                <div>
                    <span>&nbsp;</span>
                </div>
                <div>
                    <span
                        >In this context, we pointed out that cookies are used
                        on the MailChimp websites and thus personal data are
                        processed by MailChimp, its partners and service
                        providers (e.g. Google Analytics). We have no influence
                        on this data collection.</span
                    >
                </div>
                <div>
                    <span>&nbsp;</span>
                </div>
                <div>
                    <span
                        >Further information can be found in MailChimp's privacy
                        policy. We also point out the possibilities of objection
                        to the data collection for advertising purposes on the
                        websites
                        <a
                            href="http://www.aboutads.info/choices"
                            data-saferedirecturl="https://www.google.com/url?q=http://www.aboutads.info/choices&amp;source=gmail&amp;ust=1605017252159000&amp;usg=AFQjCNFLaZQ8iWa4glCujh-eTaMiZajLGg"
                            >www.aboutads.info/choices</a
                        >
                        and
                        <a
                            href="http://www.youronlinechoices.com"
                            data-saferedirecturl="https://www.google.com/url?q=http://www.youronlinechoices.com&amp;source=gmail&amp;ust=1605017252159000&amp;usg=AFQjCNFImW_JJ-Z9z5j9cMdCpWJxig4ZVg"
                            >www.youronlinechoices.com</a
                        >
                        (for the European area).</span
                    >
                </div>
                <div>
                    <span><br /></span>
                </div>
                <div>
                    <span><br /></span>
                </div>
                <div>
                    <span>USE OF GOOGLE MAPS</span>
                </div>
                <div>
                    <span
                        >This website uses Google Maps API to visually display
                        geographic information. When using Google Maps, Google
                        also collects, processes and uses data about the use of
                        the map functions by visitors. You can find more
                        information about data processing by Google in the
                        Google data protection information. There you can also
                        change your personal data protection settings in the
                        data protection center.</span
                    >
                </div>
                <div>
                    <span
                        >You can find detailed instructions for managing your
                        own data in connection with Google products here.</span
                    >
                </div>
                <div>
                    <span><br /></span>
                </div>
                <div>
                    <span><br /></span>
                </div>
                <div><span>EMBEDDED YOUTUBE VIDEOS</span></div>
                <div>
                    <span
                        >We embed YouTube videos on some of our websites. The
                        operator of the corresponding plug-ins is YouTube, LLC,
                        901 Cherry Ave., San Bruno, CA 94066, USA. When you
                        visit a page with the YouTube plug-in, a connection to
                        YouTube servers is established. In doing so, YouTube
                        will be told which pages you are visiting. If you are
                        logged into your YouTube account, YouTube can assign
                        your surfing behavior personally. You can prevent this
                        by logging out of your YouTube account beforehand.</span
                    >
                </div>
                <div>
                    <span
                        >If a YouTube video is started, the provider uses
                        cookies that collect information about user
                        behavior.</span
                    >
                </div>
            </div>
            <div>
                <span>&nbsp;</span><br /><span>&nbsp;</span>
                <div>
                    <span
                        >If you have deactivated the storage of cookies for the
                        Google ad program, you will not have to reckon with such
                        cookies when watching YouTube videos. However, YouTube
                        also stores non-personal usage information in other
                        cookies. If you want to prevent this, you have to block
                        the storage of cookies in the browser.</span
                    >
                </div>
                <div>
                    <span>&nbsp;</span>
                </div>
                <div>
                    <span>&nbsp;</span>
                </div>
                <div>
                    <span
                        >You can find more information on data protection at
                        “Youtube” in the provider's data protection declaration
                        at:
                        <a
                            href="http://www.google.de/intl/de/policies/privacy"
                            data-saferedirecturl="https://www.google.com/url?q=http://www.google.de/intl/de/policies/privacy&amp;source=gmail&amp;ust=1605017252159000&amp;usg=AFQjCNGqV6EGFWyCuw0FB4PY7QdwpQ3l3A"
                            >www.google.de/intl/de/<wbr />policies/privacy</a
                        ></span
                    >
                </div>
                <div>
                    <span><br /></span>
                </div>
                <div>
                    <span><br /></span>
                </div>
                <div><span>USE OF INSTAGRAM EMBED</span></div>
                <div>
                    <span
                        >Functions and contents of the Instagram service,
                        offered by Instagram Inc., 1601 Willow Road, Menlo Park,
                        CA, 94025, USA, can be integrated into our online offer.
                        For this purpose, e.g. Content such as images, videos or
                        texts and buttons belong with which users can express
                        their favor with regard to the content, subscribe to the
                        authors of the content or our contributions. If the
                        users are members of the Instagram platform, Instagram
                        can call the o.g. Assign content and functions to the
                        profiles of the users there. You can find Instagram's
                        privacy policy here:
                        <a
                            href="http://instagram.com/about/legal/privacy/"
                            data-saferedirecturl="https://www.google.com/url?q=http://instagram.com/about/legal/privacy/&amp;source=gmail&amp;ust=1605017252159000&amp;usg=AFQjCNGHC9btc18a6O6xwL90GrxdPW33oA"
                            >http://instagram.com/about/<wbr />legal/privacy/</a
                        >.</span
                    >
                </div>
            </div>
            <div><br /></div>
            <div>
                <br />&nbsp;<br />
                <div>
                    We use the “Instagram Feed” plug-in on our website. The
                    plug-in is developed (“provider”) by Smash Balloon LLC in
                    the USA (<a
                        href="https://smashballoon.com/"
                        data-saferedirecturl="https://www.google.com/url?q=https://smashballoon.com/&amp;source=gmail&amp;ust=1605017252159000&amp;usg=AFQjCNHNwdHD6M3z4w9MNWQ_y0nrix2Usg"
                        >https://smashballoon.com/</a
                    >) and is offered as a WordPress plug-in or as an
                    independent PHP plug-in. The plug-in enables us to display
                    the news feed from our Instagram fan page directly on our
                    website. To do this, the plug-in calls up the information
                    from Instagram and integrates it into our website. According
                    to the current state of knowledge, no special cookies are
                    specifically set by this plug-in in your browser that
                    collect, process or use personal data. Nevertheless, we
                    cannot completely rule out the possibility that personal
                    data (e.g. IP addresses, browser data, etc.) may be
                    transmitted to the provider of the plug-in or Instagram. If
                    you click on a link in the news feed, you will be redirected
                    to our Instagram channel. In this case, Instagram saves the
                    website from which you accessed our Instagram channel. For
                    more information about which data is collected, processed or
                    used when you visit the Instagram pages, please refer to
                    Instagram's privacy policy.
                </div>
                <div>&nbsp;</div>
                <div>
                    The complete range of functions of the plug-in can be viewed
                    at
                    <a
                        href="https://de.wordpress.org/plugins/instagram-feed/"
                        data-saferedirecturl="https://www.google.com/url?q=https://de.wordpress.org/plugins/instagram-feed/&amp;source=gmail&amp;ust=1605017252159000&amp;usg=AFQjCNFeZ0hJumpAxSMiXc9_YiPIml7Mjw"
                        >https://de.wordpress.org/<wbr />plugins/instagram-feed/</a
                    >
                    and
                    <a
                        href="https://smashballoon.com/instagram-feed"
                        data-saferedirecturl="https://www.google.com/url?q=https://smashballoon.com/instagram-feed&amp;source=gmail&amp;ust=1605017252159000&amp;usg=AFQjCNHaITZFXzqjDKfYZgAtuNrpLj2sKQ"
                        >https://smashballoon.com/<wbr />instagram-feed</a
                    >. Further information on data protection from Smash Balloon
                    is available here:
                    <a
                        href="https://smashballoon.com/gdpr-and-our-plugins/"
                        data-saferedirecturl="https://www.google.com/url?q=https://smashballoon.com/gdpr-and-our-plugins/&amp;source=gmail&amp;ust=1605017252159000&amp;usg=AFQjCNEX2oNMkAdgfEOW2v95xcvVCToSqg"
                        >https://smashballoon.com/gdpr-<wbr />and-our-plugins/</a
                    >
                    If you do not want Instagram to be able to assign your visit
                    to our website to your Instagram user account, please log
                    out of your Instagram user account.
                </div>
                <br />
            </div>
            <div><br /></div>
            <div>
                <div>USE OF THE CONTACT FORM</div>
                <div>
                    If you send us inquiries via the contact form, your details
                    from the inquiry form, including the contact details you
                    provided there, will be stored by us for the purpose of
                    processing the request and in case of follow-up questions.
                    We do not pass on this data without your consent.
                </div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>
                    The processing of the data entered in the contact form takes
                    place exclusively on the basis of your consent (Art. 6 Para.
                    1 lit. a GDPR). You can revoke this consent at any time. An
                    informal e-mail to us is sufficient. The legality of the
                    data processing operations carried out before the revocation
                    remains unaffected by the revocation.
                </div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>
                    Entered form data will be sent to you by e-mail, i.e. saved
                    on your e-mail server. There is no additional storage in the
                    WordPress database.
                </div>
                <div>DONATION FORM FUNDRAISING BOX</div>
                <div>
                    This website uses FundraisingBox donation forms from Wikando
                    GmbH, Schießgrabenstr. 32, 86150 Augsburg. Wikando GmbH
                    provides the technical platform for the donation process for
                    this donation form. The data you enter when making the
                    donation (e.g. address, bank details, etc.) will only be
                    stored by Wikando GmbH on servers in Germany to process the
                    donation.
                </div>
                <div><br /></div>

                <span>&nbsp;<br /></span>
                <div>
                    <span
                        >We have concluded an order data processing contract
                        with Wikando GmbH and fully implement the strict
                        requirements of the EU General Data Protection
                        Regulation and the German data protection authorities
                        when using the FundraisingBox donation forms.</span
                    >
                </div>
                <div>
                    <span
                        >The transmission of your data takes place on the basis
                        of Art. 6 Para. 1 lit. a GDPR (consent) and Art. 6 Para.
                        1 lit. b GDPR (processing to fulfill a contract).</span
                    >
                </div>
                <div>
                    <span>&nbsp;</span>
                </div>
                <div>
                    <span>If you have given your consent to data processing, you
                        can revoke your consent at any time. A revocation does
                        not affect the effectiveness of data processing
                        operations in the past.</span>
                </div>
                <div>
                    <span><br /></span>
                </div>
                <div>
                    <span><br /></span>
                </div>
                <div><span>CHANGES TO OUR PRIVACY POLICY</span></div>
                <div>
                    <span
                        >We reserve the right to adapt this data protection
                        declaration so that it always complies with current
                        legal requirements or to implement changes to our
                        services in the data protection declaration, e.g. when
                        introducing new services. The new data protection
                        declaration will then apply to your next visit.</span
                    >
                </div>
                <div>
                    <span>LEGAL BASIS OF THE DATA PROTECTION REGULATION</span>
                </div>
                <div>
                    <span
                        >In accordance with the provisions of the General Data
                        Protection Regulation (GDPR) that will apply from May
                        25, 2018, we inform you that the consent to the sending
                        of e-mail addresses is based on Art. a, 7 GDPR and § 7
                        Paragraph 2 No. 3 or Paragraph 3 UWG. The use of the
                        mailing service provider MailChimp, implementation of
                        statistical surveys and analyzes as well as logging of
                        the registration process are based on our legitimate
                        interests in accordance with. Art. 6 para. 1 lit. f
                        GDPR. We are interested in the use of a user-friendly
                        and secure newsletter system that serves both our
                        business interests and the expectations of users.</span
                    >
                </div>
                <div>
                    <span>&nbsp;</span>
                </div>
                <div>
                    <span>&nbsp;</span>
                </div>
                <div>
                    <span
                        >We also point out that you have the future processing
                        of your personal data in accordance with the legal
                        requirements in accordance with You can object to Art.
                        21 GDPR at any time. The objection can in particular be
                        made against processing for direct marketing
                        purposes.</span
                    >
                </div>
            </div>
            <div><br /></div>
            <div><br /></div>
            <div>
                <div>ASK</div>
                <div>
                    If you have any questions about data protection, please
                    write us an
                    <a href="mailto:info@childaidproject.org"
                        >email: info@childaidproject.org</a>
                </div>
                <div>&nbsp;</div>
                <div>
                    The data protection declaration was created with the data
                    protection declaration generator of activeMind AG and
                    adapted to the needs of Child Aid Project e.V.
                </div>
            </div>
        </div>
    </div>
</div>

                        </div>
                    )
                }
}

export default ImprintDe;